export const homeObjOne = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Accurate analysis of real-time data',
    headline: '球界-SportSphere',
    description:
      '球界-SportSphere is an intelligent sports information application dedicated to helping you quickly and easily obtain information about the events you are passionate about. Whether you are a sports fan or an event enthusiast, 球界-SportSphere is tailor-made for you to meet your needs.',
    buttonLabel: 'Get Started Now!',
    imgStart: '',
    img: require('../../images/logo.jpg'),
    alt: 'Credit Card',
    start: ''
  };
  
  export const homeObjTwo = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: '【Infinite Wisdom】',
    headline: 'Wonderful touch, instant understanding',
    // description:
    //   "Headline 2",
    // buttonLabel: 'Learn More',
    imgStart: '',
    img: require('../../images/img2.jpg'),
    alt: 'Vault',
    start: ''
  };
  
  export const homeObjThree = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Main Features',
    headline:
      '球界-SportSphere Main Features',
    description:
      "1. Use intuitive question and answer method to instantly query event information, including schedule, game results and real-time battle status",
    description2:
      "2. Provide the latest sports news and event updates to ensure that you will not miss any important moments.",
    description3:
      "3. Covers multiple sports and leagues, including football, basketball, tennis, baseball and other fields.",
    description4:
      "4. Support personalized customization and recommend relevant events according to your interests and preferences.",
    
    buttonLabel: 'View Details',
    imgStart: 'start',
    img: require('../../images/img1.jpg'),
    alt: 'Vault',
    start: 'true'
  };
  
  export const homeObjFour = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false, 
    topLine: '【Professional Insights】',
    headline: 'Sports decision-making, professional consulting',
    // description:
    //   'App Explanation 3',
    // buttonLabel: 'Sign Up Now',
    imgStart: 'start',
    img: require('../../images/img3.jpg'),
    alt: 'Vault',
    start: 'true'
  };
  export const homeObjFive = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true, 
    topLine: '【Personal assistant】',
    headline: 'Intelligent expert, interactive Q&A',
    // description:
    //   'App Explanation 3',
    // buttonLabel: 'Sign Up Now',
    imgStart: '',
    img: require('../../images/pa.png'),
    alt: 'Vault',
    start: ''
  };
  export const homeObjSix = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false, 
    topLine: '【Data Control】',
    headline: 'Event data, master it with one hand',
    // description:
    //   'App Explanation 3',
    // buttonLabel: 'Sign Up Now',
    imgStart: 'start',
    img: require('../../images/datacontrol.png'),
    alt: 'Vault',
    start: 'true'
  };
  export const homeObjSeven = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true, 
    topLine: '【Efficient experience】',
    headline: 'Extremely fast interaction, smart sports',
    // description:
    //   'App Explanation 3',
    // buttonLabel: 'Sign Up Now',
    imgStart: '',
    img: require('../../images/experience.png'),
    alt: 'Vault',
    start: ''
  };