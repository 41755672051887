import React from 'react';
import {InfoSec,Heading, Subtitle, BoldText} from './InfoSection.elements'
import { Container } from '../../globalStyles'

const PrivacyPolicy = () => {
    return (
        <InfoSec lightBg={true}>
            <Container>
                <Heading>User Agreement</Heading>
                <Subtitle>Welcome to the services of 球界-SportSphere ！</Subtitle>
                <Subtitle>To use the services of 球界-SportSphere, you should read and adhere to the "球界-SportSphere’s Privacy Policy】" (hereinafter referred to as "this Agreement"). Please carefully read and fully understand each term, especially those exempting or limiting liability, as well as separate agreements for certain services. Limitations and disclaimers may be highlighted in bold. Unless you have read and accepted all the terms of this Agreement, you are not authorized to use the services provided by 球界-SportSphere. Your use of the aforementioned services is deemed as your acknowledgment and agreement to be bound by the aforementioned agreement.</Subtitle>

                <Heading>1. Scope of the Agreement</Heading>
                <Subtitle>1.1 This agreement is made between you and 球界-SportSphere regarding the user's use ("use" refers to actions including but not limited to downloading, installing, launching, browsing, registering, logging in, etc., hereinafter referred to as "usage") of relevant services provided by 球界-SportSphere. "球界-SportSphere " refers to Atia Fariq. and its related service-related operating units. "This site" refers to the 球界-SportSphere website and client. "User" refers to the user of the relevant services, referred to as "You" in this agreement. Registered and non-registered users are both considered "users" under the terms of this agreement. Users who have not registered for 球界-SportSphere  products and/or services become "non-registered users" of 球界-SportSphere from the moment they start using 球界-SportSphere products and/or services and must comply with all terms in this agreement, excluding exclusive terms for registered users.</Subtitle>
                <Subtitle>1.2 This agreement applies to all products and/or services of 球界-SportSphere , including but not limited to audio and video services, advertising services, interactive entertainment, member services, and/or other products and/or services provided by 球界-SportSphere .</Subtitle>
                <Subtitle>1.3 When you use a specific service on this site, there may be separate agreements, relevant business rules, etc. (collectively referred to as "separate agreements"). Once these separate agreements are officially published, they become an integral part of this agreement, and you are also obliged to comply. When users use a specific service provided by 球界-SportSphere , their usage is considered as their agreement to the separate agreement for that specific service and all kinds of announcements issued by 球界-SportSphere in that specific service.</Subtitle>

                <Heading>2. Account and Password Security</Heading>
                <Subtitle>2.1 When using 球界-SportSphere , you need to register an account.</Subtitle>
                <Subtitle>2.2 球界-SportSphere specifically reminds you to securely manage your account and password. After use, you should log out securely. Poor management on your part may lead to account theft or password loss, for which you are solely responsible.</Subtitle>
                <Subtitle>2.3 球界-SportSphere provides you with the right to deactivate your account. You can apply for deactivation online or contact 球界-SportSphere customer service or deactivate your account through other publicly announced methods (except as otherwise provided by laws and regulations or this agreement). Once you deactivate your account, you will be unable to use 球界-SportSphere services and automatically forfeit existing rights. Please proceed with caution. Except as otherwise provided by laws and regulations, after deactivation, 球界-SportSphere will cease to provide services, and all content, information, data, and records under the products and services you used through that account will be deleted or anonymized.</Subtitle>
                <Subtitle>2.3.1 Select Profile, Click on Delete Account to remove your profile, all data will be removed from servers.</Subtitle>
                <Subtitle>2.4 TAC Usage:</Subtitle>
                <Subtitle>2.4.1 Authentication: TAC is a six (6) digit code sent to your registered phone number for transaction authentication.</Subtitle>
                <Subtitle>2.4.2 Secure Access: TAC is designed to enhance the security of your transactions. Keep your TAC confidential and do not share it with anyone.</Subtitle>
                <Subtitle>2.5 Registration:</Subtitle>
                <Subtitle>2.5.1 User Responsibility: You are responsible for providing accurate information during the registration process.</Subtitle>
                <Subtitle>2.5.2 Verification: You may be required to verify your identity through additional means during the registration process.</Subtitle>

                <Heading>3. Protection of User Personal Information</Heading>
                <Subtitle>3.1 Protecting user personal information is a fundamental principle of 球界-SportSphere .</Subtitle>
                <Subtitle>3.2 In the process of registering an account or using 球界-SportSphere services, you may need to provide some necessary information. If there are special regulations in national laws and regulations, you need to provide true identity information. If the information you provide is incomplete, you may be unable to use 球界-SportSphere services or may be restricted during use.</Subtitle>
                <Subtitle>3.3 Generally, you can browse and modify the information you submit at any time. However, for security and identity recognition (such as number appeal services), you may not be able to modify the initial registration information and other verification information provided at the time of registration.</Subtitle>
                <Subtitle>3.4 球界-SportSphere will use various security technologies and procedures to establish a complete management system to protect your personal information from unauthorized access, use, or disclosure.</Subtitle>
                <Subtitle>3.5 Without your consent, 球界-SportSphere  will not disclose your personal information to any company, organization, or individual outside of New Love Sports, except as otherwise provided by laws and regulations.</Subtitle>
                <Subtitle>3.6 球界-SportSphere attaches great importance to the protection of personal information of minors. If you are under the age of 18, you should obtain the prior consent of your parent or legal guardian (hereinafter referred to as "guardian") before using 球界-SportSphere services. Guardians should guide their children on the safety issues to pay attention to online, preventing problems in advance. If the guardian agrees to let the minor use 球界-SportSphere services, they must apply for consumption (if involved) in the name of the guardian and correctly guide and supervise the minor's use of 球界-SportSphere services. The use of 球界-SportSphere services by minors, as well as the exercise and fulfillment of rights and obligations under this agreement, is deemed to have obtained the approval of the guardian.</Subtitle>
                <Subtitle>3.7 For more information on personal information processing and protection rules, user control over personal information, please visit the full text of the product privacy policy on this site.</Subtitle>
                
                <Heading>4. Services Provided as Is</Heading>
                <Subtitle>4.1 You understand and agree that 球界-SportSphere services are provided based on existing technology and conditions. 球界-SportSphere will make the greatest effort to provide you with services, ensuring the continuity and security of the service. However, 球界-SportSphere cannot always foresee and prevent risks caused by force majeure, including but not limited to natural disasters, viruses, trojans, hacker attacks, strikes, government actions, which may lead to service interruptions, data loss, and other losses.</Subtitle>
                <Subtitle>4.2 You acknowledge and agree that due to bandwidth and network connection issues, 球界-SportSphere may experience prolonged buffering, lag, or similar situations during the provision of products and/or services. Such situations are not the fault of 球界-SportSphere, and 球界-SportSphere is not responsible for them. To reduce or avoid such situations and further improve the user experience, you acknowledge that 球界-SportSphere may employ various technical means to optimize products and/or services.</Subtitle>
            
                <Heading>5. Self-Equipped Devices</Heading>
                <Subtitle>5.1 You should understand that you need to prepare terminal equipment related to the services when using 球界-SportSphere services (such as computers, modems, etc.) and bear the associated costs (such as phone charges, internet fees, etc.).</Subtitle>
                <Subtitle>5.2 You understand and agree that using 球界-SportSphere services may consume resources such as your terminal equipment and bandwidth.</Subtitle>

                <Heading>6. Products or Services Provided by Third Parties</Heading>
                <Subtitle>When you use products or services provided by third parties on the 球界-SportSphere platform, you should comply with the user agreements of third parties in addition to adhering to the provisions of this agreement. 球界-SportSphere and third parties are responsible for disputes that may arise within the scope defined by laws and agreements.</Subtitle>

                <Heading>7. Software in 球界-SportSphere Services</Heading>
                <Subtitle>7.1 In the process of using 球界-SportSphere services, you may need to download software. For this software, 球界-SportSphere grants you a personal, non-transferable, and non-exclusive license. You may only use this software for the purpose of accessing or using 球界-SportSphere services.</Subtitle>
                <Subtitle>7.2 To improve user experience, ensure service security, and maintain product functionality consistency, 球界-SportSphere may update the software. You should update the relevant software to the latest version, and 球界-SportSphere does not guarantee its normal use otherwise.</Subtitle>

                <Heading>8. Intellectual Property Statement</Heading>
                <Subtitle>8.1 The intellectual property rights of information content provided by 球界-SportSphere services (including but not limited to web pages, text, images, audio, video, charts, etc.) belong to 球界-SportSphere. This excludes user-posted information for which the user has obtained legal intellectual property rights before using 球界-SportSphere services.</Subtitle>
                <Subtitle>8.2 Unless otherwise stated, the copyrights, patent rights, and other intellectual property rights of the software relied upon by 球界-SportSphere services belong to 球界-SportSphere .</Subtitle>
                <Subtitle>8.3 The copyright or trademark rights of commercial logos used by 球界-SportSphere  services belong to 球界-SportSphere .</Subtitle>
                <Subtitle>8.4 Any other intellectual property rights legally owned by 球界-SportSphere are protected by law. Without written permission from 球界-SportSphere , you may not use or create related derivative works in any form.</Subtitle>
                <Subtitle>8.5 Except as otherwise required by law, without written authorization from 球界-SportSphere , you and/or any third party may not illegally copy, reproduce, quote, link, crawl, or use the information content of this site in whole or in part in any way; otherwise, 球界-SportSphere has the right to pursue your legal responsibilities.</Subtitle>

                <Heading>9. User Code of Conduct</Heading>
                <Subtitle>9.1 When using 球界-SportSphere services, you must comply with laws and regulations and refrain from engaging in illegal activities, including but not limited to:</Subtitle>
                <Subtitle>(1) Publishing, transmitting, disseminating, storing content that endangers national security, disrupts social stability, violates public order and morals, is insulting, defamatory, obscene, violent, or violates any national laws and regulations.</Subtitle>
                <Subtitle>(2) Publishing, transmitting, disseminating, storing content that infringes on the legitimate rights of others, such as intellectual property and trade secrets.</Subtitle>
                <Subtitle>(3) Maliciously fabricating facts, concealing the truth to mislead or deceive others.</Subtitle>
                <Subtitle>(4) Publishing, transmitting, disseminating advertising information, and spam.</Subtitle>
                <Subtitle>(5) Engaging in activities that harm the reputation and interests of 球界-SportSphere or third parties.</Subtitle>
                <Subtitle>(6) Using nicknames or signatures with illegal, obscene, insulting, or offensive meanings.</Subtitle>
                <Subtitle>(7) Other behaviors prohibited by laws and regulations.</Subtitle>
                <Subtitle>9.2 If you violate this provision, relevant national authorities or institutions may take legal action, impose fines, or take other punitive measures against you, and may request assistance from 球界-SportSphere . If damage occurs, you are obligated to compensate according to the law, and 球界-SportSphere assumes no responsibility.</Subtitle>
                <Subtitle>9.3 If 球界-SportSphere discovers or receives reports from others that your posted information violates this provision, 球界-SportSphere has the right to independently judge and use technical means to delete, block, or disconnect links. Additionally, based on the nature of the user's behavior, 球界-SportSphere has the right to take measures including but not limited to suspending or terminating services, restricting, freezing, or terminating the use of 球界-SportSphere  accounts, and pursuing legal responsibilities.</Subtitle>
                <Subtitle>9.4 If you violate this provision and cause harm to any third party, you are solely responsible; if 球界-SportSphere suffers losses, it has the right to seek compensation from you.</Subtitle>

                <Heading>10. Compliance with Local Legal Regulations</Heading>
                <Subtitle>10.1 In the process of using 球界-SportSphere services, you must comply with local laws and regulations and respect local morals and customs. If your actions violate local laws, regulations, or moral customs, you are solely responsible.</Subtitle>
                <Subtitle>10.2 You should avoid involving 球界-SportSphere in political and public events due to your use of 球界-SportSphere services; otherwise, 球界-SportSphere has the right to suspend or terminate its services to you.</Subtitle>

                <Heading>11. User-Generated Content and Handling of Third-Party Complaints</Heading>
                <Subtitle>11.1 You fully understand and agree that the platform provided by 球界-SportSphere for user-uploaded, shared, and transmitted information is only for users to upload, share, transmit, and obtain information. The content transmitted through this platform or via this platform does not reflect the views or policies of 球界-SportSphere, and 球界-SportSphere assumes no responsibility for it. You are responsible for content sent or disseminated by you through 球界-SportSphere services, including but not limited to web pages, text, images, audio, video, charts, etc.</Subtitle>
                <Subtitle>11.2 By uploading, publishing, or transmitting images, text, audio, video, or other content on this site, you are considered the copyright owner or a lawful authorizer of the aforementioned content. Unless otherwise agreed upon by both parties, your act of publicly releasing, disseminating, and sharing the aforementioned content on this site represents your right and agreement to grant 球界-SportSphere , on a worldwide, permanent, irrevocable, free basis, the right to exercise all copyright rights (including but not limited to copying, distributing, renting, exhibiting, performing, projecting, broadcasting, filming, adapting, translating, compiling your content, and rights related to commercial development, promotion, and services, including sub licensing and rights protection), excluding personal rights exclusive to the copyright owner.</Subtitle>
                <Subtitle>11.3 You acknowledge and agree that, unless otherwise required by laws and regulations, 球界-SportSphere has no obligation to store the content you upload, publish, or share, and you should back it up yourself. If relevant laws and regulations require it, 球界-SportSphere will monitor the content you upload to the platform. You also acknowledge and agree that, unless otherwise required by laws and regulations, 球界-SportSphere is not responsible for keeping such content confidential, regardless of whether the content you provide is publicly known.</Subtitle>
                <Subtitle>11.4 If 球界-SportSphere receives a notice from the rights holder claiming that the content you sent or disseminated infringes on their relevant rights, you agree that 球界-SportSphere  has the right to independently judge and take measures such as deletion, blocking, or disconnecting links.</Subtitle>
                <Subtitle>11.5 If you violate this provision and harm the legitimate rights of any third party (including but not limited to patent rights, trademark rights, copyright and neighbouring rights, portrait rights, privacy rights, reputation rights, etc.), you shall be solely responsible. In case your violation of this provision leads to any complaints, reports, inquiries, claims, lawsuits against 球界-SportSphere or its cooperating units, or causes any reputation, prestige, or financial losses to 球界-SportSphere or its cooperating units, you should take all possible measures to ensure that New Love Sports and its cooperating units are not affected by such claims or lawsuits. At the same time, you are fully responsible for all direct and indirect economic losses suffered by 球界-SportSphere and its cooperating units due to this.</Subtitle>

                <Heading>12. Effectiveness and Modification of the Agreement</Heading>
                <Subtitle>12.1 Your use of 球界-SportSphere services is deemed as your having read this agreement and accepted the constraints of this agreement.</Subtitle>
                <Subtitle>12.2 球界-SportSphere has the right to modify the terms of this agreement when necessary. You can check the latest version of the agreement terms on the relevant service page.</Subtitle>
                <Subtitle>12.3 After the modification of the terms of this agreement, if you continue to use the software or services provided by 球界-SportSphere, it is deemed that you have accepted the modified agreement. If you do not accept the modified agreement, you should stop using the software or services provided by 球界-SportSphere.</Subtitle>

                <Heading>13. Changes, Interruptions, and Termination of Services</Heading>
                <Subtitle>13.1 球界-SportSphere may change the content of the services and may also interrupt, suspend, or terminate services.</Subtitle>
                <Subtitle>13.2 In any of the following circumstances, 球界-SportSphere has the right to interrupt or terminate the services provided to you without notice:</Subtitle>
                <Subtitle>(1) According to legal requirements, you should submit true information, but the personal information you provide is untrue or inconsistent with the information provided during registration and you fail to provide reasonable proof.</Subtitle>
                <Subtitle>(2) You violate relevant laws and regulations or the provisions of this agreement.</Subtitle>
                <Subtitle>(3) According to legal requirements or the requirements of regulatory authorities.</Subtitle>
                <Subtitle>(4) For security reasons or other necessary circumstances.</Subtitle>

                <Heading>14. Others</Heading>
                <Subtitle>14.1 Given the rapid and diversified development of the online industry, the terms stipulated in this agreement between you and 球界-SportSphere cannot comprehensively list and cover all your rights and obligations with 球界-SportSphere. Existing agreements also cannot guarantee full compliance with the needs of future developments. Therefore, the relevant statements, policies, rules, and agreements published by 球界-SportSphere on its website are supplementary agreements to this agreement, inseparable from this agreement, and have equal legal force. If you use services related to this agreement and become a member of 球界-SportSphere, it is considered that you agree to the aforementioned supplementary agreements.</Subtitle>

                <BoldText><center>Copyright 2023, 球界-SportSphere, All Rights Reserved</center></BoldText>
            </Container>
        </InfoSec>
    );
  }
  
  export default PrivacyPolicy